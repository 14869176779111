<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-alert
          v-height-fade
          dismissible
          v-model="showDismissibleAlert"
          fade
          class="mb-2"
          variant="danger"
          v-id="showDismissibleAlert == true"
        >
          <div class="alert-body">
            <span>{{ messages }}</span>
          </div>
        </b-alert>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-row>
              <b-col md="12">
                <ValidationProvider
                  name="Name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Name" label-for="mc-name">
                    <b-form-input
                      id="mc-name"
                      placeholder="name"
                      v-model="model.name"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="12">
                <p>List Permission</p>
                <b-form-group label="">
                  <b-form-checkbox
                    v-for="perm in permissions"
                    :key="perm"
                    v-model="permissioning"
                    :value="perm"
                    name="flavour-3a"
                  >
                    {{ perm }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row> </b-row>
            <b-card-footer>
              <!-- submit and reset -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-right"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'role' }"
              >
                Cancel
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}

.ql-container {
  height: 200px !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: "roles",
        permission: "admins/permissions",
        redirect: "role",
      },
      model: {
        permissions: "",
        name: "",
      },
      permissions: [],
      messages: "",
      permissioning: [],
    };
  },
  mounted() {
    let _ = this;
    _.getData();
    _.getPermission();
  },
  methods: {
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        axios
          .get(_.config.api + "/" + this.$route.params.id)
          .then((response) => {
            let _ = this;
            _.model = response.data;
            console.log(_.model);
            let datas = [];
            for (const i in _.model.permissions) {
              for (let x in _.model.permissions[i].permission) {
                if (!datas.includes(_.model.permissions[i].permission[x])) {
                  datas.push(_.model.permissions[i].permission[x]);
                }
              }
            }

            this.permissioning = datas;
          });
      }
    },
    getPermission() {
      let _ = this;

      axios.get(_.config.permission).then((response) => {
        let _ = this;
        let data = Object.entries(response.data);
        console.log(data);
        let daat_filtered = data.filter(([key, value]) => value !== "ALL");
        const justStrings = Object.fromEntries(daat_filtered);
        _.permissions = justStrings;
      });
    },
    save() {
      let _ = this;
      if (this.$route.meta.action == "store") {
        let data_post = {
          name: this.model.name,
          permissions: this.permissioning,
        };

        console.log(data_post);
        axios
          .post(_.config.api, data_post)
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Create Success",
                text: "Data has been saved",
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message[0];
            } else {
              vm.messages = e.response.data.message;
            }
          });
      } else {
        _.model.id = _.$route.params.id;

        let data_post = {
          name: this.model.name,
          permissions: this.permissioning,
        };

        axios
          .patch(_.config.api + "/" + _.$route.params.id, data_post)
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Update Success",
                text: "Data has been Updated",
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message[0];
            } else {
              vm.messages = e.response.data.message;
            }
          });
      }
    },
  },
};
</script>
